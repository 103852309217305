import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 0 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ade47951-e5d9-4569-87e9-4721d56da218",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={0} />
          <Grid.Row className={""} style={{ padding: 0 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={545}
                  businessType={"merchant"}
                  locationId={579}
                  subheader={"Catering Form"}
                  showLabels={true}
                  emailSubject={"Catering Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "Full name",
                      displayLabel: "Name",
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "Email address",
                      displayLabel: "Email",
                    },
                    {
                      type: "input",
                      label: "phone",
                      required: true,
                      inputType: "text",
                      placeholder: "Phone number",
                      displayLabel: "Phone",
                    },
                    {
                      type: "textarea",
                      label: "address",
                      required: true,
                      inputType: "textarea",
                      placeholder: "Full address",
                      displayLabel: "Address",
                    },
                    {
                      type: "input",
                      label: "guests",
                      required: true,
                      inputType: "text",
                      placeholder: "Number of guests",
                      displayLabel: "Guests",
                    },
                    {
                      type: "input",
                      label: "date",
                      required: true,
                      inputType: "text",
                      placeholder: "Event date",
                      displayLabel: "Date",
                    },
                    {
                      type: "input",
                      label: "time",
                      required: true,
                      inputType: "text",
                      placeholder: "Event time",
                      displayLabel: "Time",
                    },
                    {
                      type: "input",
                      label: "set_up_time",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Set Up Time",
                    },
                    {
                      type: "input",
                      label: "items_wanted_please_see_catering_menu",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Items Wanted (Please see Catering Menu)",
                    },
                    {
                      type: "input",
                      label:
                        "allergies_of_any_persons_if_so_please_turn_those_in_one_week_prior_to_the_event",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Allergies of any persons? if so please turn those in one week prior to the event",
                    },
                    {
                      type: "textarea",
                      label: "anything_else_you_would_like_smoothie_q_to_know",
                      required: false,
                      inputType: "textarea",
                      placeholder: "Additional details",
                      displayLabel:
                        "Anything else you would like Smoothie Q to know?",
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 21532 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
